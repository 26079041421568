<template>
  <v-row class="mt-3">
    <v-col cols>
      <v-card elevation="0" outlined>
        <div class="d-flex align-start">
          <div class="mr-auto">
            <v-card-title class="display-1">{{ count.all }}</v-card-title>
            <v-card-subtitle class="overline grey--text lighten-5"
              >All
            </v-card-subtitle>
          </div>
          <v-icon class="ma-4" color="primary">mdi-clipboard-edit</v-icon>
        </div>
      </v-card>
    </v-col>
    <v-col cols>
      <v-card elevation="0" outlined>
        <div class="d-flex align-start">
          <div class="mr-auto">
            <v-card-title class="display-1">{{ count.running }}</v-card-title>
            <v-card-subtitle class="overline grey--text lighten-5"
              >Active</v-card-subtitle
            >
          </div>
          <v-icon class="ma-4" color="primary">mdi-play</v-icon>
        </div>
      </v-card>
    </v-col>
    <v-col cols>
      <v-card elevation="0" outlined>
        <div class="d-flex align-start">
          <div class="mr-auto">
            <v-card-title class="display-1">{{ count.running }}</v-card-title>
            <v-card-subtitle class="overline grey--text lighten-5"
              >Paused</v-card-subtitle
            >
          </div>
          <v-icon class="ma-4" color="primary">mdi-pause</v-icon>
        </div>
      </v-card>
    </v-col>
    <v-col cols>
      <v-card flat outlined>
        <div class="d-flex align-start">
          <div class="mr-auto">
            <v-card-title class="display-1">{{ count.review }}</v-card-title>
            <v-card-subtitle class="overline grey--text lighten-5"
              >In Review</v-card-subtitle
            >
          </div>
          <v-icon class="ma-4" color="primary">mdi-close-network</v-icon>
        </div>
      </v-card>
    </v-col>
    <v-col cols>
      <v-card flat outlined>
        <div class="d-flex align-start">
          <div class="mr-auto">
            <v-card-title class="display-1">{{ count.completed }}</v-card-title>
            <v-card-subtitle class="overline grey--text lighten-5"
              >Completed</v-card-subtitle
            >
          </div>
          <v-icon class="ma-4" color="primary">mdi-check-all</v-icon>
        </div>
      </v-card>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    dsp_campaigns : Object
  },
  data: () => ({
    count: {
      running: 0,
      all: 0,
      completed: 0,
      review: 0,
    },
  }),
watch: {
    dsp_campaigns(){
       this.count.all =this.dsp_campaigns.data.length;
      this.count.completed = this.dsp_campaigns.data.filter((item)=>item.activity_status==="Completed").length;
      this.count.running =this.dsp_campaigns.data.filter((item) => item.activity_status ==="Active").length;
       this.count.review =this.dsp_campaigns.data.filter((item) => item.activity_status ==="In Review").length;
    }  
  },
};
</script>

