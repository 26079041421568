<template>
  <v-row class="mt-3">
    <v-col cols="12">
      <!-- <div  class="d-flex mb-3">
        <v-btn-toggle
          mandatory
          rounded
          borderless
          dense
          color="primary"
        ><v-btn >Today</v-btn>
        </v-btn-toggle></div> -->
      <v-card outlined>
        <v-card-title class="d-flex py-2">
          <h5 class="mr-auto">Campaigns Overview</h5>

          <v-select
            class="shrink"
            outlined
            dense
            return-object
            item-text="text"
            item-value="value"
            label="Status"
            @change="updateDateChartStatus($event)"
            v-model="chartStatus"
            :items="chartStatusValues"
            hide-details
            :menu-props="{ bottom: true, offsetY: true }"
          ></v-select>
        </v-card-title>
        <v-divider></v-divider>
        <v-card-text
          ><totals-apps
            v-if="app_campaigns_statistics"
            :app_campaigns_statistics="app_campaigns_statistics"
        /></v-card-text>
        <v-card-text>
          <v-row>
            <v-col cols="2"
              ><v-select
                class="shrink"
                outlined
                dense
                label="Metric"
                @change="selectSeries($event)"
                v-model="select_series"
                :items="select_charts"
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </v-col>
            <v-col cols class="pl-0 pr-0 shrink">
              <div class="d-flex align-center text-center">
                <div class="mt-2">vs</div>
              </div>
            </v-col>
            <v-col cols="2"
              ><v-select
                class="shrink"
                outlined
                dense
                label="Select a Metric"
                placeholder="Select a Metric"
                @change="selectSeries2($event)"
                v-model="select_series2"
                :items="select_charts2"
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                class="shrink"
                outlined
                dense
                return-object
                item-text="text"
                item-value="value"
                label="Period"
                @change="updateDateRange($event)"
                v-model="defaultChartDateRange"
                :items="chartDateRange"
                hide-details
                :menu-props="{ bottom: true, offsetY: true }"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="12">
              <apexchart
                type="line"
                height="300"
                v-if="apexLoading"
                :options="lineChartOptions"
                :series="series"
                ref="chart"
              ></apexchart>
            </v-col>
          </v-row>
        </v-card-text>
      </v-card>
    </v-col>
  </v-row>
</template>
<script>
import config from "@/apex-config";
import TotalsApps from "@/components/Home/TotalsApps.vue";

export default {
  components: {
    TotalsApps,
  },
  data() {
    return {
      config,
      apexLoading: false,
      select_series: "Impressions",
      select_series2: "",
      select_charts: ["Impressions", "Spend", "Clicks", "CTR"],
      select_charts2: ["Impressions", "Spend", "Clicks", "CTR"],
      defaultChartDateRange: { text: "All Days", value: "all" },
      chartDateRange: [
        { text: "Last 7 Days", value: 7 },
        { text: "Last 30 Days", value: 30 },
        { text: "All Days", value: "all" },
      ],
      chartStatus: "Active",
      chartStatusValues: ["Active", "Completed"],
      lineChartOptions: {
        chart: {
          type: "line",
          height: 300,
          zoom: {
            enabled: true,
          },
          toolbar: {
            show: false,
          },
          animation: {
            enabled: false,
          },
        },

        noData: {
          text: "No Active Campaigns...",
          align: "center",
          verticalAlign: "middle",
        },
        // theme: {
        //   palette: "palette1", // upto palette10
        // },
        colors: [config.light.primary, config.light.error],
        dataLabels: {
          enabled: false,
        },
        stroke: {
          width: [2, 2],
          dashArray: [0, 0],
          curve: ["straight", "straight"],
        },
        markers: {
          size: 3,
          style: "hollow",
          hover: {
            sizeOffset: 4,
          },
        },

        yaxis: [
          {
            opposite: false,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: 0,
            // seriesName: "Impressions",
            // title: {
            //   text: "Impressions",
            // },
            // labels: {
            //   formatter: (val) => {
            //     return val ? val.toFixed(2) : val;
            //   },
            // },
            // min: 500,
          },
          {
            opposite: true,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: 2,
            // seriesName: "Spend",
            // title: {
            //   text: "Spend",
            // },
            // labels: {
            //   formatter: (val) => {
            //     return val
            //       ? val.toLocaleString("en-us", {
            //           style: "currency",
            //           currency: "USD",
            //         })
            //       : val;
            //   },
            // },
          },
        ],

        legend: {
          opposite: false,
          show: true,
          showForNullSeries: false,
          position: "top",
          horizontalAlign: "left",
          itemMargin: {
            horizontal: 5,
            vertical: 5,
          },
        },
        grid: {
          xaxis: {
            lines: {
              show: true,
            },
          },
          yaxis: {
            lines: {
              show: true,
            },
          },
        },
      },
      series: [],
    };
  },
  mounted() {
    setTimeout(() => {
      this.apexLoading = true;
      this.generateChartData();
    });
  },
  watch: {
    app_campaigns_statistics() {
      this.generateChartData();
    },
  },
  computed: {
    app_campaigns_statistics() {
      return this.$store.state.dsp.app_campaigns_statistics;
    },
  },
  methods: {
    updateDateChartStatus() {
      this.$emit("filterReportByStatus", this.chartStatus);
    },
    updateDateRange(timeline) {
      if (timeline.value === 7) {
        this.$refs.chart.zoomX(
          new Date(this.chartDates.slice(-7)[0]).getTime(),
          new Date(this.chartDates.slice(-1)).getTime()
        );
      } else if (timeline.value === 30) {
        this.$refs.chart.zoomX(
          new Date(this.chartDates.slice(-30)[0]).getTime(),
          new Date(this.chartDates.slice(-1)).getTime()
        );
      } else if (timeline.value === "all") {
        this.$refs.chart.zoomX(
          new Date(this.chartDates[0]).getTime(),
          new Date(this.chartDates.slice(-1)).getTime()
        );
      }
    },
    getSeries() {
      const selectedSeries = this.series.find((series) => {
        return series.name == this.select_series;
      });

      return selectedSeries;
    },
    getSeries2() {
      if (this.select_series2) {
        const selectedSeries = this.series.find((series) => {
          return series.name == this.select_series2;
        });

        return selectedSeries;
      } else return { name: "", data: [] };
    },
    selectSeries() {
      this.updateChart();
    },
    selectSeries2() {
      this.updateChart();
    },
    updateChart() {
      if (this.select_series == this.select_series2) {
        this.$refs.chart.updateSeries([
          { name: this.getSeries().name, data: this.getSeries().data },
          { name: "", data: [] },
        ]);
      } else
      this.lineChartOptions = {
        ...this.lineChartOptions,
        ...{
          xaxis: {
            type: "datetime",
            categories: this.chartDates,
            labels: {
              format: "MMM dd yy",
            },
          },
          yaxis: [
          {
            opposite: false,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: this.getSeries().name=='Impressions' || this.getSeries().name=='Clicks' || this.getSeries().name=='Leads'? 0 : 2,
          },
          {
            opposite: true,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: this.getSeries2().name=='Impressions' ||this.getSeries2().name=='Clicks' || this.getSeries2().name=='Leads'? 0 : 2
          },
        ],
        },
      };
        this.$refs.chart.updateSeries([
          { name: this.getSeries().name, data: this.getSeries().data },
          { name: this.getSeries2().name, data: this.getSeries2().data },
        ]);
    },
    generateChartData() {
      this.monthlyImpressions = this.app_campaigns_statistics.map((value) =>
        parseInt(value.total_impressions)
      );
      this.monthlySpend = this.app_campaigns_statistics.map(
        (value) => parseInt((value.total_spend * 100) / 30) //added hack to return higher spend
      );
      this.monthlyClicks = this.app_campaigns_statistics.map((value) =>
        parseInt(value.total_clicks)
      );
      this.monthlyAvgCTR = this.app_campaigns_statistics.map((value) =>
        Number.parseFloat(value.average_ctr).toFixed(3)
      );

      this.chartDates = this.app_campaigns_statistics.map(
        (value) => value.date
      );

      this.series = [
        {
          name: "Impressions",
          data: this.monthlyImpressions,
        },
        {
          name: "Spend",
          data: this.monthlySpend,
        },
        {
          name: "Clicks",
          data: this.monthlyClicks,
        },
        {
          name: "CTR:",
          data: this.monthlyAvgCTR,
        },
      ];

      this.lineChartOptions = {
        ...this.lineChartOptions,
        ...{
          xaxis: {
            type: "datetime",
            categories: this.chartDates,
            labels: {
              format: "MMM dd",
            },
          },
          yaxis: [
          {
            opposite: false,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: this.getSeries().name=='Impressions' || this.getSeries().name=='Clicks' || this.getSeries().name=='Leads'? 0 : 2,
          },
          {
            opposite: true,
            show: true,
            showForNullSeries: false,
            decimalsInFloat: this.getSeries2().name=='Impressions' ||this.getSeries2().name=='Clicks' || this.getSeries2().name=='Leads'? 0 : 2
          },
        ],
        },
      };
      setTimeout(() => {
        this.selectSeries();
        this.selectSeries2();
        // this.updateDateRange(this.defaultChartDateRange);
      });
    },
  },
};
</script>
